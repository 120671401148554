.slick-dots {
  bottom: -54px;
}

.slick-dots li button:before {
  color: white;
  /* margin-top: 10px; */
  line-height: 18px;
  border-radius: 100%;
  border: 2.4px solid #267ac2;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: #26a7db !important;
  background-color: #26a7db;
}
